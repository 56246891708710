<template>
    <section class="content-header p-1 px-2">
        <div class="div-header container-fluid">
            <div class="row mb-2 content-out">
            <div class="col-sm-12 d-flex justify-content-between align-items-center">
                <h4 class="mt-3">
                    <i class="fas fa-globe"></i>
                    {{title}}
                </h4>
            </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        title: String
    },

}
</script>

<style>
h4{
  color: #0007
}
</style>